import React from 'react';

class Loading extends React.Component{

    state = {loading: ''};

    render(){
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col esd-loading cs-loading-block"></div>
                </div>
            </div>
        )
    }
}

export default Loading;