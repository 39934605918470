/*
Yksittaisen storen/liikkeen tietojen hakeminen (id:lla vai miten)?
*/


export const USERVARS = {
    //ELEMENT: '#csEdut',
    API_USER: 'csuser',
    API_PASSWORD: 'uSUzZT*&kCQ4',
    ITEMS_PER_PAGE: 12,
    URL_DIVIDER: '+'
};


export const TEXT = {

    fi: {
        offers: 'Edut',
        more : 'Katso lisää',
        back: 'Takaisin',
        previous: 'Edellinen',
        next: 'Seuraava',
        page: 'Sivu',
        tostart: 'Alkuun',
        search_results: 'Hakutulokset',
        themes: 'Teemat',
        disabled: 'Ei käytössä',

        //DB:
        ONCE: 'Kertaetu',
        ALWAYS: 'Jatkuva etu',
        TABLERESERVATION: 'Pöytävaraus',
        WEB: 'Online'
    }

}

/*
export const API_DATA = {
    headers: {
        Authorization: 'Basic ' + btoa(unescape(encodeURIComponent(USERVARS.API_USER + ':' + USERVARS.API_PASSWORD)))
    }
};
*/
/*
export const formatDate = dateIn => {
    let date = new Date(dateIn);
    let dateOut = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
        .toISOString()
        .split("T")[0];
    return dateOut;
}
*/

export const formatDatePretty = dateIn => {
    return new Date(dateIn.replace(' ', 'T')).toLocaleDateString();
}

/*
export const formatDateDay = dateIn => {
    const weekday= ["Sun","Mon","Tue","Wed","Thu", "Fri","Sat"];
    return weekday[new Date(dateIn).getDay()];
}
*/

export const mapsSelector = (q, loc) => {

    let protocol = 'https://',
    is_uiwebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
    //is_safari_or_uiwebview = /(iPhone|iPod|iPad).*AppleWebKit/i.test(navigator.userAgent);

       if(!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform) && !is_uiwebview) {
            protocol = 'maps://';
        }

    return protocol+'www.google.com/maps?q='+loc.toString()+'+'+q+'&daddr='+loc.toString()+'&ll=';
}


export const hashHandler = () => {

    var nHashArr = [[],[], 1];
    var cHashArr = nHashArr;
    const thisHash = window.location.hash;//.replace('/', '');

        if(thisHash !== '') {

            cHashArr = thisHash.replace('#', '').split('/');

                if (typeof cHashArr[0].split(USERVARS.URL_DIVIDER) === 'object') {
                    nHashArr[0] = cHashArr[0].split(USERVARS.URL_DIVIDER);
                } else nHashArr[0] = [];

                if (typeof cHashArr[1].split(USERVARS.URL_DIVIDER) === 'object') {
                    nHashArr[1] = cHashArr[1].split(USERVARS.URL_DIVIDER);
                } else nHashArr[1] = [];

                //is a number / Page
                if (typeof cHashArr[2] === 'string') {
                    nHashArr[2] = parseInt(cHashArr[2]);//.split(USERVARS.URL_DIVIDER);
                } else nHashArr[2] = 1;

                //search
                if (cHashArr[3] && cHashArr[3].indexOf('q=') >  -1) {
                    nHashArr[3] = cHashArr[3].split('q=')[1].replace(/ /g, USERVARS.URL_DIVIDER);//.toLowerCase()
                } else nHashArr[3] = '';


                if(nHashArr[0] === '*') nHashArr[0] = [];
                if(nHashArr[1] === '*') nHashArr[1] = [];
        }

    var retHashArr = nHashArr;

    //var retHashArr = [];

        /*for (var hi = 0; hi <= nHashArr.length-1; hi++) {

            retHashArr[hi] = nHashArr[hi];

               if(typeof nHashArr[hi] === 'object'){

                    for (var hi2 = 0; hi2 <= nHashArr[hi].length-1; hi2++) {
                        retHashArr[hi][hi2] = decodeURIComponent(nHashArr[hi][hi2]);
                    }

                }
        }*/

    return retHashArr;
}


/*
export const refreshHash = (arr, pos) => {

    let hashArr = window.location.hash.split('/');

    window.location.hash =
}*/


export const crapRemoval = (str) => {
    str = makeSortString(str);
    return str.replace(/[^\w\s]/gi, '-').replace(/\s/g,'').toLowerCase();
}


export const urlCreator = (str) => {
    str = makeSortString(str);
    return str.replace(/[^\w\s]/gi, '-').replace(/\s/g, '-').toLowerCase();
}


export const makeSortString = (s) => {

        if(!makeSortString.translate_re) makeSortString.translate_re = /[öäüÖÄÜ]/g;

    var translate = {
        "ä": "a", "ö": "o", "ü": "u",
        "Ä": "A", "Ö": "O", "Ü": "U"   // probably more to come
    };

    return ( s.replace(makeSortString.translate_re, function(match) {
        return translate[match];
    }));
}


export const findByDisplaynames = (haystack, arr) => {

    let ggg = arr.map((item, index) => {
        return crapRemoval(item).toLowerCase();
    });

    let catStrs = [];
    let hashCats = [];
    let catObjs = [];

    haystack.forEach(function(el) {
            if(ggg.indexOf(crapRemoval(el.displayname)) > -1) {
                catStrs.push(crapRemoval(el.displayname).toLowerCase());
                hashCats.push(el.id);
                catObjs.push(el);
            }
    });

    return [hashCats, catStrs, catObjs];
}


export const findByIDs = (haystack, arr) => {

    let ggg = arr.map((n, index) => {
        return parseInt(n);
    });

    let catStrs = [];
    let hashCats = [];
    let catObjs = [];

    haystack.forEach(function(el) {
            if(ggg.indexOf(el.id) > -1) {
                catStrs.push(crapRemoval(el.displayname).toLowerCase());
                hashCats.push(el.id);
                catObjs.push(el);
            }
    });

    return [hashCats, catStrs, catObjs];
}


export const openLink = (url) => {

    window.location.href = url;

}