import React from 'react';

import Loading from '../bits/Loading';
import API from '../apis/cs-api';
import {urlCreator, crapRemoval} from '../Common';

class App extends React.Component{


    state = {
        items: null,
        loading: true
    };


    componentDidMount(){
        this.loadCampaigns([]);
    }


    loadCampaigns = async sArr => {

        const response = await API.get('categories');
        //console.log(response);
        

        let allCats = [];

        response.data.categories.forEach((item) => {
                if(item.displayname.length > 2) allCats.push(item);
        });

        const sortedItems = allCats.sort(function(a,b) {
            return a.order - b.order;
        });

        this.setState({
            items: sortedItems,
            loading: false
        });
    };


    render(){

            if(this.state.loading){
                return(
                    <Loading />
                )
            }

        let renderedList = this.state.items.map((item, i) => {
            const k = 'cat-block-'+item.id;
            const u = '/edut/#*/' + urlCreator(crapRemoval(item.displayname))+ '/1';
            const t = 'Teema - ' + item.displayname;

    return(
            <li key={k} className="cs-list-item">
                <a
                className="btn btn-secondary btn-custom-category"
                style={{backgroundImage: 'url("' + item.logo + '")'}}
                href={u}
                title={t}
                aria-label={t}
                >
                    {item.displayname}
                </a>
            </li>)

        });


        return(
            <ul className="cs-selections-list">{renderedList}</ul>
        )
    }


}
//<img src={item.logo} alt={t} />

export default App;